import { render, staticRenderFns } from "./TuitionAndFeesDetail.vue?vue&type=template&id=7c892b65&scoped=true&"
import script from "./TuitionAndFeesDetail.vue?vue&type=script&lang=js&"
export * from "./TuitionAndFeesDetail.vue?vue&type=script&lang=js&"
import style0 from "./TuitionAndFeesDetail.vue?vue&type=style&index=0&id=7c892b65&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c892b65",
  null
  
)

export default component.exports