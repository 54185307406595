<template>
  <div id="app">

    <div class="mine_t clear">
      <img class="bg_mine" src="../../../src/assets/images/bg_mine.jpg" alt="">
      <div class="mine_avatar" :style="{backgroundImage:`url(${img})`}"></div>
      <div class="mine_details">
                 <span>
                     {{
                     customerUser != null ? customerUser.businessCustomerUser.name : ''
                   }} <font>{{ customerUser != null ? customerUser.businessCustomerUser.structure.name : '' }}</font>
                 </span>
        <label>{{ customerUser != null ? customerUser.businessCustomerUser.business.name : '' }}</label>
      </div>
    </div>

    <div v-if="!loading && !showPaymentDetail">
      <div class="tuition_list border_none">
        <div class="tuition_list_t">
          <div style="display: flex; align-items: center">
            <span style="flex: 1">{{ data.name }}</span>
            <div class="tuition_list_b">
              <ul class="clear">
                <li :class="handleStateClass(data.state)" style="padding-right: 0px; margin-right: 0px; ">
                  {{ handleState(data.state) }}
                </li>
              </ul>
            </div>
          </div>
          <label>开始时间：{{ data.startTime | dayjs }}</label>
          <label>截止时间：{{ data.endTime | dayjs }}</label>
        </div>

        <div v-for="(option, index) in data.projectList" :key="index">
          <div class="tuition_choose clear">
            <div class="tuition_choose_l" v-if="option.required === 0 && showPay">
              <van-checkbox v-model="checkedList[index]" name="1" checked-color="#34ac40"
                            @change="changeChecked"></van-checkbox>
            </div>
            <div class="tuition_choose_l" v-if="option.required === 1 && showPay">
              <van-checkbox v-model="checkedList[index]" name="2" checked-color="#34ac40" @change="changeChecked"
                            disabled></van-checkbox>
            </div>
            <div class="tuition_choose_l" v-if="!showPay">
              <van-checkbox v-model="checkedList[index]" name="3" checked-color="#34ac40" disabled></van-checkbox>
            </div>
            <div class="tuition_choose_r">
                    <span class="clear">
                        {{ option.name }}
                        <font v-if="option.required === 1">必交</font>
                      <font class="gray" v-if="option.required === 0">选交</font>
                    </span>
              <label>￥{{ option.amount }}</label>
            </div>
          </div>
        </div>

        <div class="tuition_choose choose_all clear" v-if="showPay">
          <div class="tuition_choose_l" v-if="!allCheckedRequired">
            <van-checkbox v-model="allChecked" name="3" checked-color="#34ac40" @change="changeAll"></van-checkbox>
          </div>
          <div class="tuition_choose_l" v-if="allCheckedRequired">
            <van-checkbox v-model="allChecked" name="3" checked-color="#34ac40" disabled></van-checkbox>
          </div>
          <div class="tuition_choose_r choose_all">
            <dt>全选</dt>
            <dd class="red_big">{{ checkedAmountLabel }}</dd>
            <dd class="red">￥</dd>
            <dd>已选 {{ checkedNum }} 项，合计</dd>
          </div>
        </div>
      </div>

      <div class="bottom_btn" v-if="showPay">
        <button class="green long" @click="routeToOrderConfirm">去缴费</button>
      </div>
      <div class="height_16vw"></div>
    </div>

    <div v-if="!loading && showPaymentDetail">
      <div class="tuition_list border_none">
        <div class="tuition_list_t">
          <div style="display: flex; align-items: center">
            <span style="flex: 1">{{ data.name }}</span>
            <div class="tuition_list_b">
              <ul class="clear" style="display: flex; margin-bottom: 4px">
                <li :class="handleStateClass(data.state)">
                  {{ handleState(data.state) }}
                </li>
                <li :class="handlePaymentStateClass(data.consumers.paymentState)"
                    style="margin-right: 0px; padding-right: 0px;"> {{
                    handlePaymentState(data.consumers.paymentState)
                  }}
                </li>
              </ul>
            </div>
          </div>

          <label>开始时间：{{ data.startTime | dayjs }}</label>
          <label>截止时间：{{ data.endTime | dayjs }}</label>
        </div>

        <div v-for="(option, index) in data.projectList" :key="index">
          <div class="tuition_choose clear">
            <div class="tuition_choose_l">
              <van-checkbox v-model="checkedList[index]" name="1" checked-color="#34ac40" disabled></van-checkbox>
            </div>
            <div class="tuition_choose_r">
                    <span class="clear">
                        {{ option.name }}
                        <font v-if="option.required === 1">必交</font>
                      <font class="gray" v-if="option.required === 0">选交</font>
                    </span>
              <label>￥{{ option.amount }}</label>
            </div>
          </div>
        </div>

        <div class="tuition_choose choose_all clear">
          <div class="tuition_choose_r choose_all" style="width: 100%">
            <dt></dt>
            <dd class="red_big">{{ checkedAmountLabel }}</dd>
            <dd class="red">￥</dd>
            <dd>支付合计</dd>
          </div>
        </div>

        <div class="tuition_list_t" style="margin-top: 24px">
          <span>缴费信息</span>
          <label>缴费时间：{{ data.consumers.createdTime | dayjs }}</label>
        </div>
      </div>
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical></van-loading>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import {ACCESS_CustomerUser} from '@/store/mutation-types'
import {post} from '@/utils/http'
import {formatAmount} from "../../utils/util";
import {Notify} from "vant";
import {TUITION_AND_FEES_CONFIRM_MODEL} from "../../store/mutation-types";

export default {
  name: "TuitionAndFeesDetail",
  components: {},
  data() {
    return {
      loading: true,

      customerUser: null,
      id: 0,
      data: {},
      projectIds: [],
      checkedList: [],
      bindingCardNo: '',
      img: require('../../../src/assets/images/avatar3.png'),
      allChecked: false,
      allCheckedRequired: false,
      radio: '1',
      checkedNum: 0,
      checkedAmount: 0,
      checkedAmountLabel: '0.00',
      showNotice2: false,

      showPaymentDetail: false,
      showPay: false,

      changeAllEnable: false,
    };
  },
  mounted() {
    this.customerUser = Vue.ls.get(ACCESS_CustomerUser);
    if (this.customerUser === null) {
      this.$router.replace({path: '/index'})
      return;
    }

    Vue.ls.set(TUITION_AND_FEES_CONFIRM_MODEL, null);

    this.id = this.$route.query.id;
    // this.fetchData();
    this.fetchPaymentRecord();
  },
  methods: {
    fetchData() {
      post('api/business/v1/miscellaneous/fees/{id}/read'.replace('{id}', this.id))
          .then(res => {
            if (res.data != null) {
              this.data = res.data;
              if (this.data.state === 'ON_GOING') {
                this.showPay = true;
              } else {
                this.showPay = false;
              }
              if (this.data.projectList != null && this.data.projectList.length > 0) {
                let count = 0;
                for (let i = 0; i < this.data.projectList.length; i++) {
                  if (this.data.projectList[i].required === 1) {
                    this.checkedList = this.checkedList.concat(true);
                    count++;
                  } else {
                    this.checkedList = this.checkedList.concat(false);
                  }
                }
                this.allCheckedRequired = (count === this.data.projectList.length);
                this.checkedIsAll();
              } else {
                this.checkedList = [];
              }
            }
          })
          .catch(err => {

          })
          .finally(() => {
            this.loading = false;
          })
    },
    fetchPaymentRecord() {
      const params = {
        businessCustomerUserId: this.customerUser.businessCustomerUserId,
        miscellaneousFeesId: this.id
      }

      post('api/business/v1/miscellaneous/fees/project/payment/records/details', params)
          .then(res => {
            if (null === res.data) {
              return;
            }
            if (res.data.projectList.length <= 0) {
              return;
            }
            let payed = false;
            for (let i = 0; i < res.data.projectList.length; i++) {
              const project = res.data.projectList[i];
              if (project.payCode === 1) {
                payed = true;
                break;
              }
            }

            if (payed) {
              this.data = res.data;
              this.showPaymentDetail = true;

              this.checkedList = [];
              for (let i = 0; i < this.data.projectList.length; i++) {
                if (this.data.projectList[i].payCode === 1) {
                  this.checkedList = this.checkedList.concat(true);
                } else {
                  this.checkedList = this.checkedList.concat(false);
                }
              }
              this.calcAmount();

              this.loading = false;

            } else {
              this.showPaymentDetail = false;
              this.fetchData();
            }

          })
          .catch(err => {
            this.loading = false;
          })
    },
    changeChecked() {
      this.checkedIsAll();
    },
    changeAll() {
      if (!this.changeAllEnable) {
        this.changeAllEnable = true;
        return;
      }
      let checked = true;
      if (!this.allChecked) {
        checked = false;
      }
      for (let i = 0; i < this.checkedList.length; i++) {
        if (this.data.projectList[i].required === 1) {
          continue;
        }
        this.checkedList[i] = checked;
      }
      this.calcAmount();
      this.$forceUpdate();
    },
    checkedIsAll() {
      let allChecked = true;
      for (let i = 0; i < this.checkedList.length; i++) {
        if (!this.checkedList[i]) {
          allChecked = false;
        }
      }

      if (this.allChecked !== allChecked) {
        this.changeAllEnable = false;
      }
      this.allChecked = allChecked;

      this.calcAmount();
      this.$forceUpdate();
    },
    calcAmount() {
      this.checkedNum = 0;
      this.checkedAmount = 0;
      for (let i = 0; i < this.checkedList.length; i++) {
        if (this.checkedList[i]) {
          this.checkedNum++;
          this.checkedAmount = this.checkedAmount + this.data.projectList[i].amount;
        }
      }
      this.checkedAmountLabel = formatAmount(this.checkedAmount);
    },
    routeToOrderConfirm() {
      let hadChecked = false;
      let projectList = [];
      for (let i = 0; i < this.checkedList.length; i++) {
        if (this.checkedList[i]) {
          hadChecked = true;
          projectList = projectList.concat(this.data.projectList[i]);
        }
      }

      if (!hadChecked) {
        Notify({type: 'warning', message: '请选择需要缴费的项目哦'});
        return;
      }

      Vue.ls.set(TUITION_AND_FEES_CONFIRM_MODEL, {data: this.data, projectList: projectList});

      this.$router.push({path: "/tuition/TuitionAndFeesOrderConfirm"});

    },
    handleState(state) {
      switch (state) {
        case "WAIT_START":
          return '未开始';
        case "ON_GOING":
          return '进行中';
        case "STOP":
          return '已截止';
      }
    },
    handleStateClass(state) {
      switch (state) {
        case "WAIT_START":
          return 'iconfont icon-qingjia1 dark';
        case "ON_GOING":
          return 'iconfont icon-qingjia1 blue';
        case "STOP":
          return 'iconfont icon-qingjia1 gray';
      }
    },
    handlePaymentState(state) {
      switch (state) {
        case "COMPLETED":
          return '已缴费';
        case "WAITING":
          return '未缴费';
        case "REFUND":
          return '已退款';
      }
    },
    handlePaymentStateClass(state) {
      switch (state) {
        case "COMPLETED":
          return 'iconfont icon-qian3 blue';
        case "WAITING":
          return 'iconfont icon-qian3 orange';
        case "REFUND":
          return 'iconfont icon-qian3 red';
      }
    },
  }
};
</script>

<style scoped>
::v-deep body {
  background: #ffffff !important;
}

#app {
  height: 100vh;
  background: #fff;
}

.mine_t {
  height: 28vw;
  overflow: hidden;
  position: relative;
}

.mine_t::before {
  content: '';
  width: 100%;
  height: 3vw;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.tuition_tab {
  padding: 0 3vw;
  margin-bottom: 5vw;
}

.tuition_tab_l {
  float: left;
}

.tuition_tab_l ul li {
  height: 7vw;
  line-height: 7vw;
  padding: 0 2vw;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  float: left;
  margin-right: 3vw;
}

.tuition_tab_l ul li.now {
  background: #34ac40;
  border: 1px solid #34ac40;
  color: #fff;
}

.tuition_tab_r {
  float: right;
  height: 7vw;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: right;
  flex-direction: row;
  flex-wrap: wrap;
}

.tuition_tab_r span {
  margin-right: 2vw;
}

.tuition_list {
  width: 94vw;
  margin: 0 auto;
  margin-bottom: 5vw;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 3vw;
}

.tuition_list_t {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 4vw;
}

.tuition_list_t span {
  display: block;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 1vw;
}

.tuition_list_t label {
  display: block;
  color: #999;
}

.tuition_list_b {
}

.tuition_list_b ul li {
  float: left;
  margin-right: 3vw;
  padding-right: 3vw;
  position: relative;
}

.tuition_list_b ul li:first-child::after {
  content: '';
  width: 0px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: #e5e5e5;
}

.tuition_list_b ul li.black {
  color: #4B4B4B;
}

.tuition_list_b ul li.blue {
  color: #3485ff;
}

.tuition_list_b ul li.gray {
  color: #999999;
}

.tuition_list_b ul li.orange {
  color: #ff6034;
}

/* 选项 */
.tuition_list_t span {
  font-size: 18px;
}

.tuition_list.border_none {
  border: none;
  padding-top: 0;
}

.tuition_choose {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
}

.tuition_choose.choose_all {
  padding: 2vw 0;
  padding-bottom: 4vw;
  border-bottom: 1px solid #e5e5e5;
}

.tuition_choose_l {
  width: 10%;
  height: 100%;
  float: left;
}

.tuition_choose_r {
  width: 90%;
  float: right;
  border-bottom: 1px solid #f2f2f2;
  padding: 2vw 0;
  margin-bottom: 2vw;
}

.tuition_choose_r span {
  display: block;
  font-weight: bold;
  font-size: 16px;
}

.tuition_choose_r span font {
  float: right;
  background: #ddffe0;
  color: #34ac40;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 3px;
  font-weight: normal;
}

.tuition_choose_r span font.gray {
  background: #f2f2f2;
  color: #999;
  font-weight: normal;
}

.tuition_choose_r label {
  display: block;
  color: #f66;
}

.tuition_choose_r.choose_all {
  border: none;
  padding: 0;
  margin: 0;
}

.tuition_choose_r.choose_all dt {
  float: left;
}

.tuition_choose_r.choose_all dd {
  float: right;
  line-height: 18px;
}

.tuition_choose_r.choose_all dd.red {
  color: #f66;
  font-weight: bold;
}

.tuition_choose_r.choose_all dd.red_big {
  color: #f66;
  font-size: 18px;
  font-weight: bold;
}

.bottom_btn button.long {
  width: 94vw;
  margin: 0 auto;
}

.height_16vw {
  height: 16vw;
}
</style>